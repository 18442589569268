<template>
  <v-card width="100%">
    <v-card-title class="primary--text font-weight-bold">{{
      $t('vendor.onboarding.returnedReasonTitle')
    }}</v-card-title>
    <v-card-subtitle>{{
      $t('vendor.onboarding.returnedReasonInstructions')
    }}</v-card-subtitle>
    <v-divider />
    <v-card-text class="text-pre-wrap text-body-1 font-weight-semibold">{{
      reason
    }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'OnBoardingReturnReason',
  props: {
    reason: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
